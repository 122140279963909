import axios from 'axios';
import {
  TopicDeleteResponse,
  TopicCreateResponse,
  TopicEditResponse,
  topicGetOptions,
  TopicResponse,
  TopicOptions,
  ILookupTopic,
} from './topics.types';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {stringifyQs} from '../utils/commonUtils';
import {IAsset, ITopic, IFinalizedAPIPayload} from 'spekit-types';
import {api} from '../api';

export const topic = {
  getTopics: (options?: TopicOptions): Promise<TopicResponse> => {
    options = options || {};
    const {page} = options;
    return new Promise((resolve, reject) => {
      let query = {
        limit: 25 * ((page || 0) + 1),
        offset: 25 * (page || 0),
        ordering: 'name',
      };
      fetch('/api/v1/topics/?' + stringifyQs(query))
        .then((r) => r.json())
        .then((response) => {
          resolve({
            next:
              response.count > 25 * ((page || 0) + 1)
                ? () =>
                    topic.getTopics({
                      ...options,
                      page: (page || 0) + 1,
                    })
                : undefined,
            topics: response.results || [],
          });
        });
    });
  },
  deleteTopic: (topicId: string): Promise<TopicDeleteResponse> => {
    return new Promise((resolve, reject) => {
      let status: null | number = null;
      fetch(`/api/v1/topics/${topicId}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          status = res.status;
          return res.json();
        })
        .then((res) => {
          if (status === 200) {
            let response: TopicDeleteResponse = res;
            resolve(response);
          } else {
            throw new Error(JSON.stringify(res));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createTopic: async (body: object) => {
    return api.post<TopicCreateResponse>('/api/v1/topics/', body).then((res) => res.data);
  },
  editTopic: async (topicId: string, body: object) => {
    return api
      .put<TopicEditResponse>(`/api/v1/topics/${topicId}/`, body)
      .then((res) => res.data);
  },
  get: (searchTerm?: string, options?: topicGetOptions): Promise<ILookupTopic[]> => {
    let query: any = {};
    if (searchTerm) {
      query.q = searchTerm;
    }
    if (options?.assignableOnly) {
      query.permissions = [
        'teams.list_own',
        'teams.create',
        'teams.update_own',
        'teams.delete_own',
      ].join(',');
    }

    if (options?.allowedOnly) {
      query.permissions = ['businessterms.update_own'].join(',');
    }

    let queryString = stringifyQs(query);
    return fetch('/api/v1/lookups/topics?' + queryString, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => res.results);
  },
  uploadAssets: async (formData: FormData): Promise<{file: IAsset; success: boolean}> => {
    const response = await fetch('/api/v1/topic_assets/asset_upload/', {
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    });

    const res = await response.json();

    if (!res.success) {
      throw new Error(res.message);
    }
    return res;
  },
  updateAssets: async (id: string, formData: FormData): Promise<IAsset> => {
    const response = await fetch(`/api/v1/topic_assets/${id}/update_asset/`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    });

    const res = await response.json();

    if (!res.success) {
      throw new Error(res.message);
    }
    return res;
  },
  deleteAsset: async (id: string): Promise<void> => {
    const response = await fetch(`/api/v1/topic_assets/${id}/`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
      },
    });

    const res = await response.json();

    if (!res.success) {
      throw new Error(res.message);
    }
    return res;
  },
  getTopic: async (topicId: string) => api.get<ITopic>(`/api/v1/topics/${topicId}/`),

  startMultiPartUpload: async (payload: any) => {
    return api
      .post('/api/v1/topic_assets/start_upload/', payload)
      .then((res) => res.data);
  },

  updateWithMultipart: async (fileId: string, payload: any) => {
    return api
      .post(`/api/v1/topic_assets/${fileId}/update_asset_with_multipart/`, payload)
      .then((res) => res.data);
  },

  doMultiPartUpload: async (url: string, chunk: Blob) => {
    return axios.put(url, chunk);
  },

  finalizedMultiPartUpload: async (payload: IFinalizedAPIPayload) => {
    return api.post('/api/v1/topic_assets/finalized/', payload).then((res) => res.data);
  },

  duplicateFile: async (formData: FormData): Promise<{success: boolean}> => {
    const response = await fetch(`/api/v1/topic_assets/duplicate/`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    });

    const res = await response.json();

    if (!res.success) {
      throw new Error(res.message);
    }
    return res;
  },
};
