/**
 * This file contains all the feature flags used in the webapp and extension.
 * These include constants used for chrome storage and conditional UI rendering.
 *
 * To add a new flag, add it to the enum below in `PascalCase`.
 *
 * Any flag defined here will be automatically added to the redux store (both, action and reducer) as is
 * in the webapp with the default value false unless enabled.
 * The flag in the redux store will be of the form `hasXFlag`.
 */

export enum FLAGS {
  Algolia = 'Algolia',
  APITokenGeneration = 'APITokenGeneration',
  Axios = 'Axios',
  ChatbotFeedback = 'ChatbotFeedback',
  ChromeLogging = 'ChromeLogging',
  ContentIntegration = 'ContentIntegration',
  ContentIntegrationSharepoint = 'ContentIntegrationSharepoint',
  DisableGotoSourceBtn = 'DisableGotoSourceBtn',
  CustomBranding = 'CustomBranding',
  CustomReportsDashboard = 'CustomReportsDashboard',
  DelayedExtensionUpdate = 'DelayedExtensionUpdate',
  Docker = 'Docker',
  EmptyFrameBlocker = 'EmptyFrameBlocker',
  ExtendedFileTypes = 'ExtendedFileTypes',
  Flow = 'Flow',
  GeneralRecommendations = 'Recommendations-General', // shows recommendations in all other apps
  InternalRexSetting = 'INTERNAL-RexSetting', // shows rex "engine picker" in extension settings. Used for testing
  KnowledgeChecks = 'Quiz',
  LargeFileUpload = 'LargeFileUpload',
  MultiOrg = 'MultiOrg',
  NewSearch = 'NewSearch',
  NextSpotlightsUX = 'NextSpotlightsUX',
  Progression = 'Progression',
  Quiz = 'Quiz',
  Recommendations = 'Recommendations', // enables base recommendations tech, required for others
  RecommendationsUI = 'Recommendations-SmartSend', // primary flag that enables the main rex features.
  RequestFeature = 'RequestFeature',
  RexChat = 'RexChat',
  SalesforceRecommendations = 'Recommendations-Salesforce', // shows recommendations in Salesforce
  Seismic = 'Seismic',
  SpekBehaviour = 'DefaultSpekPopoversToOnClick',
  SpekitAI = 'SpekitAI',
  SpekitShare = 'SpekitShareFiles',
  SpekLinkGeneration = 'SmartSendLinks',
  Spotlight = 'Spotlight',
  SpotlightsDashboard = 'SpotlightsDashboard',
  SpotlightsRevamp = 'SpotlightsRevamp',
  SSO = 'SSO',
  VersionHistory = 'VersionHistory',
  WikiProgression = 'WikiProgression',
  HomePageDashboards = 'HomePageDashboards',
  SmartSendV2 = 'SmartSendV2',
  SmartSendROI = 'SmartSendROI',
  NestedTopics = 'NestedTopics',
}
