import {Flex, Heading, DSButton as Button, Divider, Icon, useToast} from 'spekit-ui';
import {RiCloseLine, RiQuestionLine} from 'react-icons/ri';
import type {ITopicModalState} from './TopicModal';
import {useFormContext} from 'react-hook-form';
import {topics, utils} from 'spekit-datalayer';
import {track} from '../../../../utils/analytics';
import {useHistory, useLocation} from 'react-router-dom';
import {ITopic} from 'spekit-types';

interface HeaderProps {
  topic: ITopic | null;
  handleClose: () => void;
}

export const Header = (props: HeaderProps) => {
  const {handleSubmit} = useFormContext<ITopicModalState>();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const {topic, handleClose} = props;

  const forceRefresh = (route: string, search?: string) => {
    history.push('/empty');
    history.replace({pathname: route, search});
  };

  const getPayload = (data: ITopicModalState) => {
    return {
      name: data.name,
      description: data.description,
      font_awesome_id: data.icon.fontawesome,
      icon: data.icon.image,
      parents: data.topics.map((t) => t.value),
      teams: topic?.teams.map((t) => t.id) || [],
      all_teams: topic?.all_teams || false,
    };
  };

  const editTopic = async (data: ITopicModalState) => {
    const payload = getPayload(data);
    try {
      const updatedTopic = await topics.editTopic(topic!.id, payload);
      toast({
        variant: 'success',
        description: `${updatedTopic.name} updated`,
      });
      track('Topic Edited', {
        screen_name: 'Topic form',
        ...updatedTopic,
      });
      handleClose();
      const filters = utils.parseQs(location.search);
      if (location.pathname === '/app/wiki/') {
        if (filters.tag) {
          filters.tag = encodeURIComponent(updatedTopic.name);
        }

        history.push({
          pathname: `${location.pathname}`,
          search: utils.stringifyQs(filters),
        });
      } else {
        forceRefresh('/app/wiki/topics', utils.stringifyQs(filters));
      }
    } catch (error) {
      toast({
        variant: 'error',
        description: error?.response?.data?.message,
      });
    }
  };

  const createTopic = async (data: ITopicModalState) => {
    const payload = getPayload(data);

    try {
      const createdTopic = await topics.createTopic(payload);
      toast({
        variant: 'success',
        description: 'Topic created',
      });
      track('Topic Created', {
        screen_name: 'Topic form',
        ...data,
      });
      handleClose();
      history.push(
        `/app/wiki/?topic=${createdTopic.id}&tag=${encodeURIComponent(createdTopic.name)}`
      );
    } catch (error) {
      toast({
        variant: 'error',
        description: error?.response?.data?.message,
      });
    }
  };

  const onSubmit = (data: ITopicModalState) => {
    if (topic) {
      editTopic(data);
    } else {
      createTopic(data);
    }
  };

  return (
    <Flex alignItems='center'>
      <Heading
        as='h4'
        fontWeight='semibold'
        fontSize='large'
        data-testid='topic-modal-heading'
      >
        {topic ? 'Edit Topic' : 'Create Topic'}
      </Heading>
      <Flex alignItems='center' ml='auto'>
        <Button
          variant='icon'
          icon={<Icon as={RiQuestionLine} />}
          size='medium'
          aria-label='Help center'
          colorScheme='white'
          tooltipLabel='Help center'
          tooltipPlacement='bottom'
          onClick={() => {
            window.open(
              'https://help.spekit.com/hc/en-us/sections/9922002747803-Topics',
              '_blank'
            );
          }}
          mr={12}
        />
        <Button
          variant='contained'
          size='medium'
          colorScheme='primary'
          mr={20}
          onClick={handleSubmit(onSubmit)}
        >
          {topic ? 'Save changes' : 'Publish'}
        </Button>
        <Divider orientation='vertical' h='32px' mr={10} />
        <Button
          variant='icon'
          icon={<Icon as={RiCloseLine} />}
          size='medium'
          aria-label='Close'
          colorScheme='white'
          tooltipLabel='Close'
          tooltipPlacement='bottom'
          onClick={handleClose}
        />
      </Flex>
    </Flex>
  );
};
