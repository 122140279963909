import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {IOptionType, ITopic} from 'spekit-types';
import {Divider, DSModal as Modal, ModalBody} from 'spekit-ui';
import {closeTopicModal} from '../../../../redux/actions';
import {RootState} from '../../../../reduxStore';
import {Details} from './Details';
import {Header} from './Header';
import {schema} from './validations';
import {useCallback, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export interface ITopicModalState {
  name: string;
  description: string;
  icon: {
    image: ITopic['icon'] | string | null;
    fontawesome: string | null;
  };
  topics: IOptionType[];
}

const initialValues = {
  name: '',
  description: '',
  icon: {
    image: null,
    fontawesome: null,
  },
  topics: [],
};

export const TopicModal = () => {
  const {toggle: isOpen, term: topic} = useSelector(
    (state: RootState) => state.topicModal
  );

  const methods = useForm<ITopicModalState>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (topic && isOpen) {
      methods.reset({
        name: topic.name || '',
        description: topic.description || '',
        icon: {
          image: topic.icon || null,
          fontawesome: topic.font_awesome_id || null,
        },
        topics: topic.parents?.map((parent) => ({
          label: parent.name,
          value: parent.id,
        })),
      });
    }
  }, [topic, methods, isOpen]);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    methods.reset(initialValues);
    if (location.pathname.includes('/create')) {
      history.push(location.pathname.replace('/create', ''));
    }

    dispatch(closeTopicModal());
  }, [location.pathname, history, dispatch, methods]);

  if (!isOpen) return null;

  return (
    <Modal
      size='lg'
      scrollBehavior='outside'
      onClose={handleClose}
      isOpen={isOpen}
      closeOnEsc={false}
      autoFocus={false}
      trapFocus={false}
      blockScrollOnMount={false}
    >
      <FormProvider {...methods}>
        <Header topic={topic} handleClose={handleClose} />

        <ModalBody mt={32}>
          <Details topic={topic} />
          <Divider orientation='horizontal' my={32} />
        </ModalBody>
      </FormProvider>
    </Modal>
  );
};
